import React from "react";
import logo from 'assets/img/taladteedin-logo.png';
import { 
    Nav,
} from "reactstrap";
import { NavLink, Switch, Route, Redirect, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faBars, 
    faSignHanging,
    faBullhorn,
    faHeart,
    faHeartCirclePlus,
    faUserGear,
    faHome,
    faCrown,
    faHandshake
} from "@fortawesome/free-solid-svg-icons";
import blank_profile from 'assets/img/blank-profile.png';
import FavoriteMN from "./favorite";
import FavoriteArea from "./favoriteArea";
import AnnonceMn from "./annonceMn";
import Sale from "./sale";
import Profiles from "./profiles";
import Providinglands from "./providingland";
import { LoginContext } from 'components/loginContext/LoginContext';

const RouterDash = [
    {
        name: "ข้อมูลส่วนตัว",
        path: "/profile",
        icon: faUserGear,
        component: Profiles,
        layout: "/dashboard"
    },
    {
        name: "รายการโปรด",
        path: "/favorite",
        icon: faHeart,
        component: FavoriteMN,
        layout: "/dashboard"
    },
    {
        name: "รายการประกาศขาย",
        path: "/sale",
        icon: faSignHanging,
        component: Sale,
        layout: "/dashboard"
    },
    {
        name: "รายการประกาศหา",
        path: "/announce",
        icon: faBullhorn,
        component: AnnonceMn,
        layout: "/dashboard"
    },
    {
        name: "ทำเลโปรด",
        path: "/favoritearea",
        icon: faHeartCirclePlus,
        component: FavoriteArea,
        layout: "/dashboard"
    },
    {
        name: "จัดหาที่ดิน",
        path: "/providingland",
        icon: faHandshake,
        component: Providinglands,
        layout: "/dashboard"
    },
]

function Dashboard(){
    const history = useHistory();
    const { userData } = React.useContext(LoginContext);
    const [hide, setHide] = React.useState(false)

    React.useState(() =>{
        if(!userData){
            history.push('/')
        }
    },[userData])

    return(
        <>
            <div className={`user-sidebar ${hide ? "hide" : ""}`}>
                <div style={{width:'100%', display:'flex', justifyContent:'center', marginBottom: 20, marginLeft:'auto', marginRight:'auto', paddingTop: 20}}>
                    {userData ? 
                    <div style={{width:'90%', display:'flex', justifyContent:'center',flexDirection:'column', wordBreak:'break-all', alignItems:'center', marginLeft:'auto', marginRight:'auto'}}>
                        <img alt="" src={userData.profileImg ? userData.profileImg : blank_profile} style={{width: 50, height:50, borderRadius: '50%'}} className="mb-10" />
                        <p>{userData.name}</p>
                        <div className="mb-20" >
                            {userData.role === "PREMIUM" ? 
                            <div style={{display:'flex', padding: '5px 10px', backgroundColor: '#F4D03F', color: 'white', borderRadius: 5}}>
                                <FontAwesomeIcon icon={faCrown} style={{fontSize: 16}} />
                            </div>
                            : 
                            <div style={{display:'flex', padding: '5px 10px', backgroundColor: '#EF9751', color: 'white', borderRadius: 5}}>
                                ผู้ใช้ทั่วไป
                            </div>
                            }
                        </div>

                        <hr className="full-w"/>
                    </div> 
                    : null}
                </div>
                
                <Nav>
                    {RouterDash.map((prop, i) =>{
                        if (prop.redirect) return null
                        return(
                            <NavLink
                            key={i}
                            className="list" 
                            activeClassName="active" 
                            to={prop.layout + prop.path}
                            // onClick={() => setHide(true)}
                            >
                                <b></b>
                                <b></b>
                                <div className="btn-link">
                                    <span className="icon">
                                        <FontAwesomeIcon className="faIcon" icon={prop.icon} />
                                    </span>
                                    <span className="title">{prop.name}</span>
                                </div>
                            </NavLink>
                        )
                    })}
                </Nav>
            </div>
            <div className={`user-navbar ${hide ? "hide" : ""}`}>
                <div className="container-fluid">
                    <div style={{display:'flex', alignItems:'center'}}>
                        <button
                        style={{
                            border:'none',
                            backgroundColor:'transparent',
                            marginRight: 10,
                            cursor:'pointer',
                            padding: 10
                        }}
                        onClick={() => setHide(!hide)}
                        >
                            <FontAwesomeIcon icon={faBars} style={{fontSize: 22}} />
                        </button>
                        {/* <img alt="logo" src={logo} style={{ height:40}} /> */}
                    </div>
                    <div>
                        <img alt="logo" src={logo} style={{height:40}} />
                    </div>
                    <div>
                        <button
                        style={{
                            border:'none',
                            backgroundColor:'transparent',
                            marginRight: 10,
                            cursor:'pointer',
                            padding: 10,
                            display:'flex',
                            alignItems:'center',
                            flexDirection:'row',
                            fontWeight: 500
                        }}
                        onClick={() => history.push("/")}
                        >
                            <FontAwesomeIcon icon={faHome} style={{fontSize: 16, marginRight: 10}} />
                            หน้าแรก
                        </button>
                    </div> 
                </div>
            </div>
            <main className="user-main">
                <Switch>
                    {RouterDash.map((prop, key) =>{
                        return (
                            <Route  
                            path={prop.layout + prop.path}
                            component={prop.component}
                            key={key}
                            />
                        )
                    })}
                    <Redirect from="/dashboard" to="/dashboard/profile" />  
                </Switch>
            </main>
        </>
    )
}
export default Dashboard;