import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap"

import Home from "views/Home";
import ForSale from "views/ForSale";
import ServicePage from "views/ServicePage";
import Announce from "views/Announce";
import AnnounceResult from "views/AnnounceResult";
import Map from "views/Map";
import HelpCenter from "views/HelpCenter";
import Content from "views/Content";
import About from "views/About";
import EstateDetail from "views/screens/EstateDetail";
import ErrorPage from "views/error404";
import ContentView from "views/ContentView";
import PrivacyPolicy from "views/PolicyPrivate";
import Packages from "views/Packages";
import ConsignmentMn from "views/userDashboard/consignmentMn";
import ConsignmentView from "views/ConsignmentView";
import SecretProject from "views/SecretProject";
import Providinglandview from "views/ProvidingLandView";
import Tools from "views/packageTab/Tools";
import Searching from "views/Searching";
import Notification from "views/userDashboard/Notification";
import Payments from "views/Payment";
import Dashboard from "views/userDashboard/dashboard";
import PageRouter from "views/screens/PageRouter";
import AnnounceDetail from "views/AnnounceDetail";
// import MyMaps from "views/screens/MyMaps";
import { LoginContext } from './components/loginContext/LoginContext';
import axios from "axios";
import Exemples from "views/screens/Exemple";
import { PageProvider } from "views/screens/PageContext";

import 'assets/scss/styles.scss';
import 'assets/css/bootstrap-grid.css';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-multi-carousel/lib/styles.css";
import 'react-photoswipe/lib/photoswipe.css';
// import 'swiper/scss'; // core Swiper
// import 'swiper/scss/navigation'; // Navigation module
// import 'swiper/scss/pagination'; // Pagination module
// import 'swiper/scss/virtual';
// import 'swiper/scss/free-mode'

function App() {
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

  const [visible, setVisible] = React.useState(false);
  const { LoginSession } = React.useContext(LoginContext);

  React.useEffect(() => {
    function condi(){
      var condition = localStorage.getItem("condition");
      if(condition === null || condition === undefined){
        setVisible(true)
      }
    }
    condi()
    LoginSession()
  },[])

  function submitCon(){
    localStorage.setItem("condition", true)
    setVisible(false)
  }

  return (
    <BrowserRouter >
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route path="/forsale" render={(props) => <ForSale {...props} />} />
        <Route path="/services" render={(props) => <ServicePage {...props} />} />
        <Route path="/announce" render={(props) => <Announce {...props} />} />
        <Route path="/announce_all" render={(props) => <AnnounceResult {...props} />} />
        <Route path="/announce_view/:id" render={(props) => <AnnounceDetail {...props} />} />
        <Route path="/map" render={(props) => <Map {...props} />} />
        <Route path="/helpCenter" render={(props) => <HelpCenter {...props} />} />
        <Route path="/content" render={(props) => <Content {...props} />} />
        <Route path="/contentview/:id" render={(props) => <ContentView {...props} />} />
        <Route path="/about" render={(props) => <About {...props} />} />
        <Route path="/estate_detail" render={(props) => <EstateDetail {...props} />} />
        <Route path="/privacy" render={(props) => <PrivacyPolicy {...props} />} />
        <Route path="/packages" render={(props) => <Packages {...props} />} />
        <Route path="/consignment" render={(props) => <ConsignmentMn {...props} />} />
        <Route path="/consignmentview" render={(props) => <ConsignmentView {...props} />} />
        <Route path="/secretproject" render={(props) => <SecretProject {...props} />} />
        <Route path="/providinglandview" render={(props) => <Providinglandview {...props} />} />
        <Route path="/tools" render={(props) => <Tools {...props} />} />
        <Route path="/searching" render={(props) => <Searching {...props} />} />
        <Route path="/notification" render={(props) => <Notification {...props} />} />
        <Route path="/payments/package/:id" render={(props) => <Payments {...props} />} />
        <Route path="/dashboard" render={(props) => <Dashboard {...props} />} />
        <Route path="/examples" render={(props) => <Exemples {...props} />} />
        <PageProvider>
          <Route path="/page/:name" render={(props) => <PageRouter {...props} />} />
        </PageProvider>
        <Route path="*" render={(props) => <ErrorPage {...props} />} />
      </Switch>
      <Modal isOpen={visible}>
        <ModalHeader>เงื่อนไขการใช้บริการ</ModalHeader>
        <ModalBody>
          เราจำเป็นต้องขอให้ท่านยอมรับเงื่อนไขการใช้บริการ ท่านสามารถเข้าอ่านเงื่อนไขได้ที่ 
          <a href="#" style={{marginLeft: 10}} className="color-default"><u>เงื่อนไขการใช้บริการ</u></a>
        </ModalBody>
        <ModalFooter>
          <Button 
          color="default" 
          className="btn-sm"
          onClick={submitCon}
          >
            ยอมรับเงื่อนไข
          </Button>
        </ModalFooter>
      </Modal>
    </BrowserRouter>
  );
}

export default App;
