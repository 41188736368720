import React from "react";
import { 
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Form,
    Input,
    Label,
    FormGroup,
    FormText,
    Spinner,
    Alert
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLine, faGoogle } from '@fortawesome/free-brands-svg-icons';
// import {
//     LoginSocialFacebook,
//     LoginSocialGoogle,
// } from 'reactjs-social-login';
// import { GoogleLogin } from 'react-google-login';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { ErrorAlert, SuccessAlert } from "components/alert";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import liff from "@line/liff";
import { LoginContext } from "./loginContext/LoginContext";

const siteKey = process.env.REACT_APP_API_SITE_KEY;
const path_url = process.env.REACT_APP_API_CONNECT;
const line_liff_id = process.env.REACT_APP_LINE_LIFF_ID;
// const google_key = process.env.REACT_APP_GOOGLE_API_KEY;
const facebook_key = process.env.REACT_APP_FACEBOOK_API_KEY;

function LoginModal(props){
    const { LoginSuccess } = React.useContext(LoginContext);
    const [googleLoading, setGoogleLoading] = React.useState(false);
    const [login, setLogin] = React.useState({
        email:'',
        password: ''
    });
    const [regisLoad, setRegisLoad] = React.useState(false);
    const [otpLoad, setOtpLoad] = React.useState(false);
    const [otpPin, setOtpPin] = React.useState('');
    const [registerData, setRegisterData] = React.useState({
        email: '',
        password: '',
        re_password: '',
        name: '',
        tel: '',
        backup_tel: ''
    });
    const [reCaptChaVal, setReCaptChaVal] = React.useState(null);
    const [runTimer, setRunTimer] = React.useState(false);
    const [registerOpen, setRegisterOpen] = React.useState(false);
    const [otpOpen, setOtpOpen] = React.useState(false);
    const [countDown, setCountDown] = React.useState(0);
    const [facebookLoad, setFacebookLoad] = React.useState(false);
    const [resetPass, setResetPass] = React.useState({
        email: '',
        new_pass : '',
        renew_pass : ''
    })
    const [resetModal, setResetModal] = React.useState(false);
    const [errorAlert, setErrorAlert] = React.useState(false);

    const {isOpen, onClose} = props;

    const registerToggleClose = () =>{
        setRegisterOpen(false)
        setRegisterData({
            email: '',
            password: '',
            re_password: '',
            name: '',
            tel: '',
            backup_tel: ''
        })
    }

    React.useEffect(() => {
        let timerId;
    
        if (runTimer) {
          setCountDown(60 * 5);
          timerId = setInterval(() => {
            setCountDown((countDown) => countDown - 1);
          }, 1000);
        } else {
          clearInterval(timerId);
        }
    
        return () => clearInterval(timerId);
    }, [runTimer]);

    React.useEffect(() => {
        if (countDown < 0 && runTimer) {
          console.log("expired");
          setRunTimer(false);
          setCountDown(0);
          setOtpOpen(false);
        }
    }, [countDown, runTimer]);
    
    React.useEffect(() => {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: facebook_key,
            cookie: true,
            xfbml: true,
            version: 'v18.0',
          });
        };
    }, []);

    const togglerTimer = () => setRunTimer((t) => !t);

    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

    const onLoginSubmit = () =>{
        axios.post(path_url+'/user/login',{
            email: login.email,
            password: login.password
        }).then(res =>{
            LoginSuccess(res.data)
            setTimeout(() => {
                window.location.reload()
                setLogin({
                    email:'',
                    password:''
                })
            }, 500);
        })
        .catch((error) =>{
            if(error.response.status === 400){
                ErrorAlert({text: 'Email หรือ Password ไม่ถูกต้อง!'})
            }else{
                ErrorAlert({text: 'ไม่พบ Email บัญชีนี้'})
            }
        })
    }

    const [otpResponse, setOtpRespone] = React.useState(null)

    const onRegisterSubmit = (e) =>{
        e.preventDefault();
        setRegisLoad(true);
        if(reCaptChaVal){
            if(registerData.email && 
                registerData.password && 
                registerData.re_password &&
                registerData.name &&
                registerData.tel
            ){
                if(registerData.password === registerData.re_password){
                        axios.post(path_url+"/user/otpRequest",{
                            email: registerData.email,
                            phone: registerData.tel
                        }).then(res =>{
                            if(res.status === 200){
                                setOtpRespone(res.data)
                                setRegisterOpen(false);
                                setOtpOpen(true);
                                togglerTimer();
                                setRegisLoad(false);
                            }
                        }).catch(e =>{
                            if(e.response.status === 404){
                                setRegisLoad(false);
                                ErrorAlert({text: 'Email หรือ หมายเลขโทรศัพท์ นี้ มีการใช้งานแล้ว'})
                            }else{
                                setRegisLoad(false);
                                ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'})
                            }
                        })
                }else{
                    setRegisLoad(false);
                    ErrorAlert({text: 'กรุณากรอก Password ให้ตรงกัน'})
                }
                
            }else{
                setRegisLoad(false);
                ErrorAlert({text: 'กรุณากรอกข้อมูลให้ครบถ้วน'})
            }
        }else{
            setRegisLoad(false);
            ErrorAlert({text: 'กรุณายืนยันว่าคุณไม่ใช่ Robot'})
        }
    }

    const onOtpSubmit = (e) =>{
        e.preventDefault();
        setOtpLoad(true);
        axios.post(path_url+"/user/otpVerify",{
            token: otpResponse.token,
            otp_code: otpPin
        },{
            accept: 'application/json'
        }).then(res =>{
            if(res.status === 200){
                axios.post(path_url+"/user/register",{
                    email: registerData.email,
                    password: registerData.password,
                    name: registerData.name,
                    tel: registerData.tel,
                    tel_backup: registerData.backup_tel
                })
                .then(res =>{
                    if(res.status === 200){
                        setOtpOpen(false);
                        togglerTimer();
                        SuccessAlert();
                        setOtpRespone(null);
                        setOtpLoad(false);
                        setRegisterData({
                            email: '',
                            password: '',
                            re_password: '',
                            name: '',
                            tel: '',
                            backup_tel: ''
                        })
                        setOtpPin('')
                    }
                })
                .catch(() =>{
                    setOtpLoad(false);
                    ErrorAlert({text: 'บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง'})
                })
            }
        }).catch(() =>{
            setOtpLoad(false);
            ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'})
        })
    }

    function onChange(val, child){
        setRegisterData((item) =>{
            const data = {...item}
            data[child] = val
            return{...data}
        })
    }

    function onChangLogin(val, child){
        setLogin(item =>{
            const data = {...item}
            data[child] = val
            return{...data}
        })
    }

    function onChangeCaptcha(value) {
        setReCaptChaVal(value)
    }

    function GoogleLoginSubmit(data){
        
        axios.post(path_url+'/user/loginWithGoogle',{
            name: data?.name,
            email: data?.email,
            id: data?.sub,
            image: data?.picture
        })
        .then(res =>{
            if(res.status === 200){
                LoginSuccess(res.data);
                setTimeout(() => {
                    window.location.reload();
                    setGoogleLoading(false);
                }, 500);
            }
        })
        .catch(e =>{
            setGoogleLoading(false);
            ErrorAlert({text: 'ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง'})
        })
    }

    function initLine(){
        liff.init({ liffId: line_liff_id }, () => {
          if (liff.isLoggedIn()) {
            runApp();
          } else {
            liff.login();
          }
        }, err => console.error(err));
    }

    const runApp = () => {
        liff.getProfile().then(profile => {
            lineLoginConnect(profile)
        }).catch(err => console.error(err));
    }

    async function lineLoginConnect(profile){
        try{
            const res = await axios.post(path_url+'/user/loginWithLine', {...profile})
            if(res.status === 200){
                LoginSuccess(res.data);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
        }catch(e){
            console.log(e)
            ErrorAlert({text: 'เกิดข้อผิดพลาด ไม่สามารถเข้าสู่ระบบด้วย LINE ได้ขณะนี้'})
        }
    }

    function LineLogin(){
        initLine()
    }

    async function FacebookLoginSubmit(data){
        try{
            const res = await axios.post(path_url+'/user/loginWithFacebook', {
                userID: data?.id,
                email: data?.email,
                profile_img : data?.picture?.data?.url,
                name: data?.name
            })

            if(res.status === 200){
                LoginSuccess(res.data);
                setTimeout(() => {
                    window.location.reload();
                    setFacebookLoad(false)
                }, 1000);
            }
        }catch(e){
            console.log(e)
            setFacebookLoad(false)
            ErrorAlert({text: 'เกิดข้อผิดพลาด ไม่สามารถเข้าสู่ระบบด้วย FACEBOOK ได้ขณะนี้'})
        }
    }

    function reNewPasswordModalClose(){
        setResetPass({
            email: '',
            new_pass: '',
            renew_pass: ''
        })
        setResetModal(false)
    }

    async function reNewPassword(){
        
        try{
            if(resetPass.new_pass === resetPass.renew_pass){
                const res = await axios.post(path_url+'/user/userReNewPassword',{
                    email: resetPass.email,
                    password: resetPass.new_pass
                })

                if(res.status === 200){
                    SuccessAlert()
                    setTimeout(() => {
                        reNewPasswordModalClose()
                    }, 1500);
                }
            }else{
                setErrorAlert(true)
                setTimeout(() => {
                    setErrorAlert(false)
                },3000)
            }
        }catch(e){
            if(e.response.status === 400){
                ErrorAlert({text: 'ไม่พบอีเมลผู้ใช้งานนี้'})
            }else{
                console.error(e)
            }
        }
    }

    const handleFacebookLogin = () => {
        window.FB.login((response) => {
          if (response.authResponse) {
            console.log('Logged in:', response);
            window.FB.api('/me', { fields: 'name,email,picture' }, (userInfo) => {
                FacebookLoginSubmit(userInfo);
            });
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        });
    };

    const loginGoogle = useGoogleLogin({
        scope: "profile email openid",
        onSuccess: async (tokenResponse) => {
            const idToken = tokenResponse;
            try {
                const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${idToken.access_token}`,
                  },
                });
                const userProfile = await response.json();
                GoogleLoginSubmit(userProfile); // This will contain user profile data
              } catch (error) {
                console.error('Error fetching user profile:', error);
              }
        },
        onError: () => {
            console.log('Login Failed');
        },
    });

    return(
        <>
        {/* login */}
        <Modal isOpen={isOpen} toggle={onClose} fade>
            <ModalHeader toggle={onClose}>เข้าสู่ระบบ</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup className="form-group" style={{borderBottom: '0.5px solid #000'}}>
                            <Label>Email</Label>
                            <Input 
                            type="email" 
                            placeholder="example@mail.com" 
                            value={login.email}
                            onChange={(val) => onChangLogin(val.target.value, 'email')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup className="form-group" style={{borderBottom: '0.5px solid #000'}}>
                            <Label>Password</Label>
                            <Input 
                            type="password" 
                            placeholder="password" 
                            value={login.password}
                            onChange={(val) => onChangLogin(val.target.value, 'password')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-20">
                    <Col style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button 
                        type="button" 
                        color="transparent"
                        onClick={() => setResetModal(true)}
                        >
                            ลืมรหัสผ่าน
                        </Button>
                    </Col>
                </Row>
                <Row className="mb-20">
                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Button color="default" className="full-w" onClick={() => onLoginSubmit()}>
                            เข้าสู่ระบบ
                        </Button>
                    </Col>
                </Row>
                <Row className="mb-20">
                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Button type="button" color="sky" className="full-w" onClick={()=> setRegisterOpen(!registerOpen)}>
                            สมัครสมาชิก
                        </Button>
                    </Col>
                </Row>
                <div style={{borderBottom: '1px solid gray'}} className="mb-20" />
                <div style={{display:'flex', justifyContent:'center'}} className="mb-20">
                    <label style={{fontSize: 20, fontWeight: 500}}>เข้าสู่ระบบด้วย</label>
                </div>
                <Row className="mb-20">
                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Button 
                        color="facebook"
                        className="full-w"
                        onClick={handleFacebookLogin}>
                            Login with Facebook
                        </Button>
                        {/* <SocialButton
                        provider="facebook"
                        className="btn btn-facebook full-w"
                        appId={process.env.REACT_APP_FACEBOOK_API_KEY}
                        onLoginSuccess={(data) =>{
                            setFacebookLoad(true)
                            FacebookLogin(data)
                        }}
                        onLoginFailure={(err) => {
                            setFacebookLoad(false)
                            console.log(err);
                        }}
                        scope="public_profile,email"
                        version="v19.0"
                        >
                            <FontAwesomeIcon icon={faFacebookF} className="mr-10" style={{fontSize:20}}/>
                            Sign in with Facebook
                            {facebookLoad ? 
                                <Spinner size='sm' className="loading" />
                            : null}
                        </SocialButton> */}
                        {/* <LoginSocialFacebook
                        appId={process.env.REACT_APP_FACEBOOK_API_KEY}
                        cookie={true}
                        className="btn btn-facebook full-w"
                        version="v18.0"
                        fieldsProfile={
                        'id,name,picture,email'
                        }
                        onLoginStart={() => setFacebookLoad(true)}
                        onResolve={({ provider, data }) => {
                            FacebookLogin(data)
                        }}
                        onReject={err => {
                            setFacebookLoad(false)
                            console.log(err);
                        }}
                        >
                            <FontAwesomeIcon icon={faFacebookF} className="mr-10" style={{fontSize:20}}/>
                            Sign in with Facebook
                            {facebookLoad ? 
                                <Spinner size='sm' className="loading" />
                            : null}
                        </LoginSocialFacebook> */}
                    </Col>
                </Row>
                <Row className="mb-20">
                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Button
                        onClick={() => loginGoogle()}
                        color="google"
                        className="full-w"
                        >
                            <FontAwesomeIcon icon={faGoogle} className="mr-10" style={{fontSize:20}} />
                            Sign in with Google
                            {googleLoading ? 
                                <Spinner size='sm' className="loading" />
                            : null}
                        </Button>
                        {/* <GoogleLogin
                        className="btn btn-google full-w"
                        clientId={google_key} // Replace with your Google Client ID
                        buttonText="Login with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                        // accessType="offline"
                        scope="profile email" // Specify permissions you want to request
                        /> */}
                        {/* <SocialButton
                        provider="google"
                        autoLogin={true}
                        className="btn btn-google full-w"
                        appId={process.env.REACT_APP_GOOGLE_API_KEY}
                        onLoginSuccess={(data) =>{
                            GoogleLogin(data)
                            setGoogleLoading(true)
                        }}
                        onLoginFailure={(err) => {
                            setGoogleLoading(false)
                            console.log(err)
                        }}
                        scope="email profile"
                        >
                            <FontAwesomeIcon icon={faGoogle} className="mr-10" style={{fontSize:20}} />
                            Sign in with Google
                            {googleLoading ? 
                                <Spinner size='sm' className="loading" />
                            : null}
                        </SocialButton> */}
                        {/* <LoginSocialGoogle 
                        client_id={process.env.REACT_APP_GOOGLE_API_KEY}
                        className="btn btn-google full-w"
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        access_type="offline"
                        onLoginStart={() => setGoogleLoading(true)}
                        onResolve={val =>{
                            GoogleLogin(val.data);
                        }}
                        onReject={err => {
                            setGoogleLoading(false)
                            console.log(err);
                        }}
                        >
                            <FontAwesomeIcon icon={faGoogle} className="mr-10" style={{fontSize:20}} />
                            Sign in with Google
                            {googleLoading ? 
                                <Spinner size='sm' className="loading" />
                            : null}
                        </LoginSocialGoogle> */}
                    </Col>
                </Row>
                <Row className="mb-20">
                    <Col>
                        <Button 
                        type="button" 
                        color="line" 
                        className="flex align-center justify-center full-w"
                        onClick={() => LineLogin()}
                        >
                        <FontAwesomeIcon icon={faLine} className="mr-10" style={{fontSize:20}} />
                        Sign in with Line
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>

        {/* register */}
        <Modal isOpen={registerOpen} toggle={registerToggleClose}>
            <ModalHeader toggle={registerToggleClose}>
                สมัครสมาชิก <label className="color-default" style={{fontSize: '1em', fontWeight: 500}}>ตลาดที่ดิน</label>
            </ModalHeader>
            <Form onSubmit={onRegisterSubmit}>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup className="form-group">
                            <Label>Email <Label className="color-red">*</Label></Label>
                            <Input 
                            type="email" 
                            className="border" 
                            placeholder="Email" 
                            value={registerData.email}
                            onChange={(val) => onChange(val.target.value, 'email')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup className="form-group">
                            <Label>Password <Label className="color-red">*</Label></Label>
                            <Input 
                            type="password"
                            className="border" 
                            placeholder="Password" 
                            value={registerData.password}
                            onChange={(val) => onChange(val.target.value, 'password')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup className="form-group">
                            <Label>ยืนยัน Password <Label className="color-red">*</Label></Label>
                            <Input 
                            type="password" 
                            className="border" 
                            placeholder="Password" 
                            value={registerData.re_password}
                            onChange={(val) => onChange(val.target.value, 're_password')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr style={{marginBottom:10}}/>
                <Row>
                    <Col>
                        <FormGroup className="form-group">
                            <Label>ชื่อ-สกุล <Label className="color-red">*</Label></Label>
                            <Input 
                            type="text" 
                            className="border" 
                            placeholder="name" 
                            value={registerData.name}
                            onChange={(val) => onChange(val.target.value, 'name')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup className="form-group">
                            <Label>เบอร์โทรติดต่อ <Label className="color-red">*</Label></Label>
                            <Input 
                            type="tel" 
                            className="border" 
                            placeholder="Tel. No" 
                            value={registerData.tel}
                            onChange={(val) => onChange(val.target.value, 'tel')}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup className="form-group">
                            <Label>เบอร์โทรติดต่อสำรอง</Label>
                            <Input 
                            type="tel" 
                            className="border" 
                            placeholder="Tel. No" 
                            value={registerData.backup_tel}
                            onChange={(val) => onChange(val.target.value, 'backup_tel')}
                            />
                            <FormText style={{color:'red'}}>* กรณีติดต่อหมายเลขหลักไม่ได้</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <ReCAPTCHA 
                    sitekey={siteKey}
                    onChange={onChangeCaptcha}
                    />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="default">
                    {regisLoad ? 
                    <>
                        <Spinner size={'sm'} className="mr-10" />
                        Loading...
                    </>
                    :
                    'ยืนยันการสมัคร'
                    }
                </Button>
            </ModalFooter>
            </Form>
        </Modal>

        {/* otp */}
        <Modal isOpen={otpOpen} toggle={() => setOtpOpen(!otpOpen)}>
            <ModalHeader toggle={() => setOtpOpen(!otpOpen)}>ยืนยัน OTP</ModalHeader>
            <Form onSubmit={onOtpSubmit}>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup className="form-group">
                            <Label>OTP ถูกส่งไปยังหมายเลข {registerData.tel}</Label>
                            <Input 
                            type="text" 
                            className="border" 
                            placeholder="name" 
                            value={otpPin}
                            onChange={(val) => setOtpPin(val.target.value)}
                            />
                            <Label>ยืนยัน OTP ในอีก {minutes}:{seconds}</Label>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="default">
                    {otpLoad ? 
                    <>
                        <Spinner size={'sm'} className="mr-10" />
                        Loading...
                    </>
                    :
                    'ยืนยัน OTP'
                    }
                </Button>
            </ModalFooter>
            </Form>
        </Modal>

        {/* ResetPassword */}
        <Modal isOpen={resetModal} toggle={reNewPasswordModalClose}>
            <ModalHeader toggle={reNewPasswordModalClose}>ลืมรหัสผ่านใช่ไหม?</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup className="form-group">
                        <Label>Email</Label>
                        <Input 
                        type="text"
                        className="border"
                        value={resetPass.email}
                        onChange={(val) => setResetPass({...resetPass, email: val.target.value})}
                        />
                    </FormGroup>
                    <hr className="mb-10" />
                    <FormGroup className="form-group">
                        <Label>รหัสผ่านใหม่</Label>
                        <Input 
                        type="password"
                        className="border"
                        value={resetPass.new_pass}
                        onChange={(val) => setResetPass({...resetPass, new_pass: val.target.value})}
                        />
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Label>กรอกรหัสผ่านใหม่อีกครั้ง</Label>
                        <Input 
                        type="password"
                        className="border"
                        value={resetPass.renew_pass}
                        onChange={(val) => setResetPass({...resetPass, renew_pass: val.target.value})}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="default" className="btn-sm" onClick={reNewPassword}> บันทึก </Button>
                <Button color="transparent" className="btn-sm" onClick={reNewPasswordModalClose}> ยกเลิก </Button>
            </ModalFooter>
        </Modal>
        <Alert fade color="danger" isOpen={errorAlert}>
            รหัสผ่านไม่ตรงกัน กรุณากรอกใหม่อีกครั้ง
        </Alert>
        </>
    )
}
export default LoginModal