import React from "react";
import Navbars from "routers/navbars";
import Footer from "components/footer";
import { useParams } from 'react-router-dom'
import { ErrorAlert } from "components/alert";
import axios from "axios";
import {
    FacebookShareButton,
    LineShareButton,
    TwitterShareButton
} from 'react-share';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faLine,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import ReactPlayer from "react-player";
import { Adsense } from "@ctrl/react-adsense";
import { Helmet, HelmetData } from 'react-helmet-async';

const path_url = process.env.REACT_APP_API_CONNECT;
const share_url = process.env.REACT_APP_SHARE_URL+'contentview/';

const helmetData = new HelmetData({});

function ContentView(){
    const [fetch, setFetch] = React.useState(null);
    var { id } = useParams();

    // function useQuery() {
    //     const { search } = useLocation();
    //     return React.useMemo(() => new URLSearchParams(search), [search]);
    // }

    // let query = useQuery();
    
    React.useEffect(() =>{
        fetchData()
        
    },[])

    const fetchData = async () =>{
        try{
            const res = await axios.post(path_url+'/content/getContentById',{
                _id: id
            })
            if(res.status === 200){
                setFetch(res.data)
                // metaTag(res.data.data)
            }
        }catch(e){
            ErrorAlert({text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'})
        }
    }

    function createMarkup(val) {
        return {__html: val ? val : ""};
    }

    // function metaTag(data){
    //     document.title = data?.title
    //     document.getElementsByTagName('meta')[3].content = data?.title; //title
    //     document.getElementsByTagName('meta')[4].content = data?.title; //desdescription
    //     document.getElementsByTagName('meta')[5].content = data?.tags.map((item) => item).join(","); //keyword
    //     document.getElementsByTagName('meta')[6].content = data?.title; //og:title
    //     document.getElementsByTagName('meta')[7].content = data?.title; //og:description
    //     document.getElementsByTagName('meta')[9].content = `https://www.taladteedin.com/contentview/${data?._id}`; //og:url
    //     document.getElementsByTagName('meta')[10].content = data?.coverPath; //og:image
    //     document.getElementsByTagName('meta')[14].content = data?.title; //twitter:title
    //     document.getElementsByTagName('meta')[15].content = data?.title; //twitter:description
    //     document.getElementsByTagName('meta')[16].content = data?.coverPath; //twitter:image
    //     document.getElementsByTagName('meta')[17].content = `https://www.taladteedin.com/contentview/${data?._id}`; //twitter:site
    // }

    return(
        <>
        <Navbars />
        <Helmet prioritizeSeoTags helmetData={helmetData}>
            <title>{fetch?.data?.title}</title>
            <meta name="title" content={fetch?.data?.title} />
            <meta name="description" content={fetch?.data?.title}/>
            <meta property="og:title" content={fetch?.data?.title} />
            <meta property="og:description" content={fetch?.data?.title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://www.taladteedin.com/contentview/${fetch?.data?._id}`} />
            <meta property="og:image" content={fetch?.data?.coverPath} />
            <meta property="og:image:type" content="image/jpg" />
            <meta property="fb:app_id" content="1202029513835683" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={fetch?.data?.title} />
            <meta name="twitter:description" content={fetch?.data?.title} />
            <meta name="twitter:image" content={fetch?.data?.coverPath} />
            <meta name="twitter:site" content={`https://www.taladteedin.com/contentview/${fetch?.data?._id}`} />
        </Helmet>
        <div className="content-view-contianer">
        {fetch && 
        <>
            <h1 className="header tltd-header">
                {fetch.data.title}
                <div style={{fontSize: 18}} className="color-gray">{new Date(fetch.data.create_At).toLocaleString('th', {day:'2-digit', month:'long', year:'2-digit'})}</div>
            </h1>
            {fetch.data.type === 'image' ? 
            <img alt="" src={fetch.data.coverPath} style={{width:'100%'}} className="mb-20" />
            :
            <ReactPlayer 
            url={fetch.data.coverPath}
            controls={true}
            width={'100%'}
            height={700}
            style={{paddingBottom: 20}}
            />
            }
            <hr className="mb-20" style={{border:'0.5px solid gray'}} />
            <div className="content-view-wrapper mb-30">
                <div className="content-view-body">
                    {/* <div 
                    style={{display:'flex', flexDirection:'row'}}
                    className="mb-20"
                    >
                        <div style={{fontWeight: 500, fontSize: 18}}>{fetch.data.name}</div>
                        <div style={{marginLeft:10, marginRight: 10}}> - </div>
                        <div style={{fontSize: 18}}>{new Date(fetch.data.create_At).toLocaleString('th', {day:'2-digit', month:'long', year:'2-digit'})}</div>                        
                    </div> */}
                    <div 
                    dangerouslySetInnerHTML={createMarkup(fetch ? fetch.data.html : "")} 
                    className="content-view-block"
                    />
                </div>
                <div className="content-view-property">
                    <div className="content-view-block">
                        <div className="btn-share-wrapper shadow mb-30">
                            <p className="mb-10">Share Post</p>
                            <div className="mb-20">
                                <FacebookShareButton 
                                url={`${share_url+id}`} 
                                className="btn-share-facebook mr-10"
                                
                                >
                                    <FontAwesomeIcon icon={faFacebookF} style={{color:'white'}} />
                                </FacebookShareButton>
                                <LineShareButton
                                url={share_url+id}
                                className="btn-share-line mr-10"
                                >
                                    <FontAwesomeIcon icon={faLine} style={{color:'white'}} />
                                </LineShareButton>
                                <TwitterShareButton
                                url={share_url+id}
                                className="btn-share-twitter mr-10"
                                >
                                    <FontAwesomeIcon icon={faTwitter} style={{color:'white'}} />
                                </TwitterShareButton>
                            </div>
                        </div>
                        <div className="more-content-wrapper shadow">
                            <p className="mb-20">บทความน่าสนใจ</p>
                            {fetch ?
                            <>
                            {
                                Array.from(fetch.highlight).filter(item => item.type === 'image').map((item, i) =>{
                                    return(
                                        <div key={i} 
                                        className="highlight-content mb-10"
                                        onClick={() => window.location.href = `/contentview/${item._id}`}
                                        >
                                            <img alt="" src={item.coverPath} />
                                            <div className="text">
                                                {item.title}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <Adsense
                            client="ca-pub-7792492639332740"
                            className="mb-20"
                            slot="4815627610"
                            style={{ display: 'block', width: '100%' }}
                            responsive={true}
                            layout="in-article"
                            format="fluid"
                            />
                            </>
                            
                            : null}
                        </div>
                    </div>
                </div>
            </div>
            <Adsense
            client="ca-pub-7792492639332740"
            className="mb-20"
            slot="4815627610"
            style={{ display: 'block', width: '100%' }}
            responsive={true}
            layout="in-article"
            format="fluid"
            />
            <div className="content-tags mb-20">
                <p>Tags : </p>
                {Array.from(fetch.data.tags).map((item,i) =>{
                    return(
                        <div key={i} className="tags">
                            {item}
                        </div>
                    )
                })}
            </div>
            <div className="content-sign mb-20">
                <p className="title">ผู้เขียน : {fetch.data.name}</p>
            </div>
            <Adsense
            client="ca-pub-7792492639332740"
            className="mb-20"
            slot="4815627610"
            style={{ display: 'block', width: '100%' }}
            responsive={true}
            layout="in-article"
            format="fluid"
            />
        </>
        }
        </div>
        <Footer />
        </>
    )
}
export default ContentView;