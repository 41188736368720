import React from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    FormGroup,
    InputGroup,
    Input,
    Label,
    Button,
    CardText,
    CardTitle,
    Tooltip
} from 'reactstrap';
import { 
    faLocationDot,
    faSearch,
    faMapLocationDot,
    faChevronUp,
    faChevronDown,
    faCrown
} from "@fortawesome/free-solid-svg-icons";
import { PROVINCE_LIST } from "./components/province";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import Pagination from "./pagination/Pagination";
import { useHistory } from 'react-router-dom';

import Navbars from "routers/navbars";
import Footer from "components/footer";
import axios from "axios";

const path_url = process.env.REACT_APP_API_CONNECT;

function Searching(){
    const [searchParam, setSearchParam] = React.useState({
        province: "",
        size: {
            min: {
                count: 0,
                unit: ''
            },
            max:{
                count: 0,
                unit: ''
            }
        },
        price:{
            min: 0,
            max: 0
        },
    });
    const [current, setCurrent] = React.useState(1);
    const [pageSize] = React.useState(12);
    const [fetchData, setFetchData] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [searchTooltip, setSearchTooltip] = React.useState(false);
    const [searchActive, setSearchActive] = React.useState(null);
    const history = useHistory()

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    React.useEffect(() =>{
        const province = query.get('province');
        const size = JSON.parse(query.get('size'));
        const price = JSON.parse(query.get('price'));
        if(province){
            setSearchParam((prev) => {
                return{...prev, province: province ? province : ""}
            })
        }
        if(size){
            setSearchParam((prev) => {
                return{
                    ...prev, 
                    size: {
                        min: {
                            count: size.min.count ? parseFloat(size.min.count) : 0,
                            unit: size.min.unit ? size.min.unit : '',
                        },
                        max: {
                            count: size.max.count ? parseFloat(size.max.count) : 0,
                            unit: size.max.unit ? size.max.unit : '',
                        }
                    }
                }
            })
        }
        if(price){
            setSearchParam((prev) =>{
                return{
                    ...prev,
                    price: {
                        min: price.min ? price.min : 0,
                        max: price.max ? price.max : 0,
                    }
                }
            })
        }
        filtering({province, size, price}, current)
    },[])

    async function filtering(data, page=1){
        const skip = (page - 1) * pageSize;
        try{
            const res = await axios.post(path_url+'/sale/searchByFilter',{
                skip: skip,
                province: data.province,
                size: data.size,
                price: data.price
            })

            if(res.status === 200){
                setFetchData(res.data.data)
                setCount(res.data.count.qty)
            }
        }catch(e){
            console.log(e)
        }
    }

    const onChange = (val, props) =>{
        setSearchParam((item) =>{
            const data = {...item}
            data[props] = val
            return data
        })
    }

    const onChangeSize = (val, prop, child) =>{
        setSearchParam((item) =>{
            const data = {...item}
            data['size'][prop][child] = val
            return{...data}
        })
    }

    const onChangePrice = (val, prop) =>{
        setSearchParam((item) =>{
            const data = {...item}
            data['price'][prop] = parseFloat(val)
            return{...data}
        })
    }

    function MoreContent(val){
        setCurrent(val);
        filtering(searchParam,val);
        scrollToTop()
    }

    function scrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    }

    const intl = new Intl.NumberFormat('th-TH', {
        style: 'currency',
        currency: 'THB',
        minimumFractionDigits: 0,
    })

    const toEstateDetail = (val) =>{
        window.open(`/estate_detail/?sale=${val}`, "_blank") 
    }

    const toMapPage = () => {
        history.push(`/map?${searchParam?.province ? 'province='+searchParam.province+"&" : ""}${searchParam.size.min.count || searchParam.size.max.count ? 'size='+JSON.stringify(searchParam.size)+"&":''}${searchParam.price.min || searchParam.price.max ? 'price='+JSON.stringify(searchParam.price):''}`)
    }

    const toggle = () => setSearchTooltip(!searchTooltip);

    return(
        <>
        <Navbars />
        <div className="tltd-searching-container">
            <div className="tltd-searching-box">
                <Button className="iconVisible" onClick={() => setSearchActive(searchActive ? null : 'active')}>
                    ค้นหาเพิ่มเติม
                    <FontAwesomeIcon icon={searchActive ? faChevronUp : faChevronDown} style={{marginLeft:10}} />
                </Button>
                <Row className={"search-wrapper " + searchActive}>
                    <Col className="col-12 col-md-3">
                        <FormGroup className="form-group">
                            <Label>ค้นหาตามจังหวัด</Label>
                            <InputGroup>
                                <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5, color:'#23265F'}} />
                                <Input 
                                list="browsers" 
                                name="browser" 
                                className="form-list" 
                                placeholder="จังหวัด" 
                                value={searchParam?.province}
                                onChange={(val) => onChange(val.target.value, 'province')}
                                />
                                <datalist id="browsers" className="list" >
                                    {PROVINCE_LIST.map((item, k) =>{
                                        return <option key={k} value={item.province_name} />
                                    })}
                                </datalist>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col className="col-md-4 col-12">
                        <FormGroup className="form-group">
                            <Label>ค้นหาหาตามขนาด</Label>
                            <Row>
                                <div className="col-md-5 col-sm-5">
                                    <InputGroup>
                                        <Input 
                                        type="number" 
                                        placeholder="0" 
                                        value={searchParam?.size?.min?.count}
                                        onChange={(val) => onChangeSize(val.target.value, 'min', 'count')}
                                        />
                                        <select 
                                        className="form-select"
                                        value={searchParam?.size?.min?.unit}
                                        onChange={(val) => onChangeSize(val.target.value, 'min', 'unit')}
                                        >
                                            <option value={''}>เลือก</option>
                                            <option value={'wa'}>ตร.ว</option>
                                            <option value={'ngan'}>งาน</option>
                                            <option value={'rai'}>ไร่</option>
                                        </select>
                                    </InputGroup>
                                </div>
                                <div className="col-md-1 col-sm-1">
                                    <Label>ถึง</Label>
                                </div>
                                <div className="col-md-5 col-sm-5">
                                    <InputGroup>
                                        <Input 
                                        type="number" 
                                        placeholder="0" 
                                        value={searchParam?.size?.max?.count}
                                        onChange={(val) => onChangeSize(val.target.value, 'max', 'count')}
                                        />
                                        <select 
                                        className="form-select"
                                        value={searchParam?.size?.max?.unit}
                                        onChange={(val) => onChangeSize(val.target.value, 'max', 'unit')}
                                        >
                                            <option value={''}>เลือก</option>
                                            <option value={'wa'}>ตร.ว</option>
                                            <option value={'ngan'}>งาน</option>
                                            <option value={'rai'}>ไร่</option>
                                        </select>
                                    </InputGroup>
                                </div>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col className="col-md-4 col-12">
                        <FormGroup className="form-group">
                            <Label>ราคาระหว่าง</Label>
                            <Row>
                                <div className="col-md-5 col-sm-5">
                                    <InputGroup>
                                        <Input 
                                        type="number"
                                        placeholder="ต่ำสุด" 
                                        value={searchParam?.price?.min}
                                        onChange={(val) => onChangePrice(val.target.value, 'min')}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="col-md-1 col-sm-1">
                                    <Label>ถึง</Label>
                                </div>
                                <div className="col-md-5 col-sm-5">
                                    <InputGroup>
                                        <Input 
                                        type="number"
                                        placeholder="สูงสุด" 
                                        value={searchParam?.price?.max}
                                        onChange={(val) => onChangePrice(val.target.value, 'max')}
                                        />
                                    </InputGroup>
                                </div>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col className="col-md-1 col-sm-12" style={{alignItems:'center', display:'flex'}}>
                        <Button 
                        id="tooltip1"
                        style={{
                            width: 40,
                            height: 40,
                            backgroundColor: '#EF9751',
                            borderRadius: 40/2,
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            color:'white'
                        }}
                        onClick={() => filtering(searchParam,1)}
                        >
                            <FontAwesomeIcon icon={faSearch} style={{marginRight:5}} />
                        </Button>
                        <Tooltip
                        isOpen={searchTooltip}
                        target="tooltip1"
                        toggle={toggle}
                        >
                            ค้นหา
                        </Tooltip>
                    </Col>
                </Row>
            </div>
            <div className="tltd-searching-body">
                <div className="mb-20">
                    ผลการค้นหา {count} รายการ
                </div>
                <Row className="row-cols-1 row-cols-md-4">
                    {fetchData.map((item, i) =>{
                        return(
                            <Col className="mb-30" key={i}>
                                <Card
                                style={{cursor:'pointer'}}
                                onClick={() => toEstateDetail(item?._id)}
                                >
                                    <div className="card-tag">
                                        {item?.saleType === "OWNER" ?
                                        <div className="tag bg_default color-white mt-10">
                                            เจ้าของขายเอง
                                        </div>
                                        :
                                        null}
                                        <div className="tag-right">
                                        {item?.package?.type === "PERPLOT" || item?.user?.role === "PREMIUM" ? 
                                        <div className="tag-1 color-warning">
                                            <FontAwesomeIcon icon={faCrown} style={{fontSize: 25}} className="icon" />
                                        </div>
                                        :null}
                                        </div>
                                        <CardImg 
                                        alt={''}
                                        src={item?.images[0]?.path} 
                                        />
                                    </div>
                                    <CardBody>
                                        <div className="body-1">
                                            <CardTitle>
                                                {item?.title}
                                            </CardTitle>
                                            <CardText>
                                                {item?.description}
                                            </CardText>
                                        </div>
                                        <div className="body-2">
                                            <CardText>
                                                {intl.format(item?.totalPrice)}
                                            </CardText>
                                            {item?.pricePerWa ?
                                                <CardText className="color-default">{intl.format(item?.pricePerWa)}/ตร.ว.</CardText>
                                            :null}
                                        </div>
                                        <CardText>
                                            <FontAwesomeIcon icon={faLocationDot} style={{marginLeft:5, marginRight:5}} />
                                            {item?.saleDistrict + ", " + item?.saleProvince}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </div>
            <div className="tltd-searching-footer mb-30">
                <Pagination 
                className="pagination-bar"
                currentPage={current}
                totalCount={count}
                pageSize={pageSize}
                onPageChange={page => MoreContent(page)}
                />
            </div>
        </div>
        <div className="show_on_map">
            <Button 
            style={{
                display:'flex',
                backgroundColor:'black',
                color:'white',
                padding: 10,
                borderRadius: 10
            }}
            onClick={() => toMapPage()}
            >
                <FontAwesomeIcon icon={faMapLocationDot} className="mr-10" />
                แสดงบนแผนที่
            </Button>
        </div>
        <Footer />
        </>
    )
}
export default Searching;